import { defineMessages } from "react-intl";

export const scope = "mobileDownloadModal";

export default defineMessages({
  appTitle: {
    id: `${scope}.appTitle`,
    defaultMessage: "L'application ila26",
  },
  availableOnMobile: {
    id: `${scope}.availableOnMobile`,
    defaultMessage: "{available} sur mobile !",
  },
  available: {
    id: `${scope}.available`,
    defaultMessage: "Disponible",
  },
  availableOnMobileDescription: {
    id: `${scope}.availableOnMobileDescription`,
    defaultMessage: "Disponible sur mobile !",
  },
  downloadDescription: {
    id: `${scope}.downloadDescription`,
    defaultMessage: "Pour vous suivre partout, même sur vos chantiers.",
  },
});
