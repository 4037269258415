import { api,typesenseApi, usersApiSlice } from "@/services/api";
import fakeCommentsReducer from "@/store/tasks/commentsSlice";
import fakeFilesReducer from "@/store/tasks/filesSlice";
import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import CategoriesReducer from "./category/CategoriesSlices";
import certificatesReducer from "./certificates/CertificateSlices";
import eventsOfNewsReducer from "./event/EventSlices";
import inboxReducer from "./inbox/InboxSlices";
import { knowledgeBaseReducer } from "./knowledge/knownledgeBaseSlice";
import librariesSlices from "./library/librariesSlices";
import articleOfNewsReducer from "./news/NewsSlices";
import toggleSidebarReducer from "./sidebar/toggleSidebarSlice";
import { languageReducer } from "./translations";
import usersReducer from "./users/usersSlice";
import usersProfileReducer from "./profile/user/UsersSlices";
import tenantReducer, {
  TenantsType,
  selectTenant,
  shouldSelectTenantAction,
} from "./tenants/tenantsSlice";
import KanbanReducer from "./tasks/kanbanSlice";
import chatPopupReducer from "./chat-popup";

import { PERSIST, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { msalInstance } from "@/config/msalConfig";
import { tenantsApi } from "./tenants/endpoints";
import { NonEmptyArray, decideMultiTenancy } from "@/utils/auth/tenants";
import { TenantPlanType, TenantType } from "./tenants/endpoints/tenants";
import { themeReducer } from "@/theme/theme-reducer.ts";

export const persistConfig = {
  key: "tenant",
  storage,
};

export const persistNavbarConfig = {
  key: "navbar",
  storage,
};

const persistedTenantReducer = persistReducer(persistConfig, tenantReducer);

const persistedNavBarReducer = persistReducer(persistNavbarConfig, toggleSidebarReducer);

// Extracted async logic
const handleTenantNotFoundErrorAsync = async (
  action: AnyAction,
  myApi: MiddlewareAPI<Dispatch<AnyAction>, any>
) => {
  const {
    payload: { status, data },
    meta,
  } = action;

  if (meta.arg.endpointName === "getTenantsList") {
    // cannot get tenant
    location.pathname = "logout-session";
    return;
  }
  if (status === 401 && data.errorCode === "user_not_found") {
    // redundancy from previous error, user disconnected but userId persisted
    location.pathname = "logout-session";
    return;
  }
  if (status === 401 && data.errorCode === "tenant_header_not_found") {
    const { tenant } = myApi.getState() as { tenant: TenantsType };
    const userId = msalInstance.getActiveAccount()?.localAccountId;

    if (tenant.userId && tenant.userId !== userId) {
      // a mismatch between msal and local cache
      location.pathname = "logout-session";
      return;
    }

    if (!tenant.tenantsList) {
      const tenantsListRetrieved = (
        await myApi.dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          tenantsApi.endpoints.getTenantsList.initiate(undefined, { forceRefetch: true })
        )
      ).data;

      if (tenantsListRetrieved && tenantsListRetrieved.length > 0) {
        const { shouldSelectTenant, selectedTenant } = decideMultiTenancy(
          tenantsListRetrieved as unknown as NonEmptyArray<TenantType>
        );

        if (shouldSelectTenant) {
          // multiple enterprise and business tenants
          myApi.dispatch(
            shouldSelectTenantAction({
              shouldSelectTenant: true,
              tenantsList: selectedTenant as TenantType[],
            })
          );
          return;
        } else {
          // single basic or business or enterprise tenant
          myApi.dispatch(
            shouldSelectTenantAction({
              shouldSelectTenant: false,
              tenantsList:
                (selectedTenant as TenantType[]).length > 1 // check if is array or object
                  ? (selectedTenant as TenantType[])
                  : ([selectedTenant] as TenantType[]),
            })
          );
          myApi.dispatch(
            selectTenant({
              tenantId:
                (selectedTenant as TenantType[]).length > 1 // check if is array or object
                  ? (selectedTenant as TenantType[]).filter(
                      tenant => tenant.plan !== TenantPlanType.BASIC
                    )[0]?.id
                  : (selectedTenant as TenantType).id,
            })
          );
          return;
        }
      } else {
        // no tenant
        location.pathname = "logout-session";
        return;
      }
    }
  }
};

const handleTenantNotFoundErrorMiddleware: Middleware = myApi => next => action => {
  if (isRejectedWithValue(action)) {
    handleTenantNotFoundErrorAsync(action, myApi)
      .then(() => {
        return next(action);
      })
      .catch(_error => {
        return next(action);
      });
  } else {
    return next(action);
  }
};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [usersApiSlice.reducerPath]: usersApiSlice.reducer,
  [typesenseApi.reducerPath]: typesenseApi.reducer,
  articlesOfNews: articleOfNewsReducer,
  categories: CategoriesReducer,
  eventsOfNews: eventsOfNewsReducer,
  libraries: librariesSlices,
  inbox: inboxReducer,
  users: usersReducer,
  knowledgeBase: knowledgeBaseReducer,
  certificates: certificatesReducer,
  fakeComments: fakeCommentsReducer,
  language: languageReducer,
  theme: themeReducer,
  fakeFiles: fakeFilesReducer,
  toggleSidebar: persistedNavBarReducer,
  usersProfile: usersProfileReducer,
  tenant: persistedTenantReducer,
  kanban: KanbanReducer,
  chatPopup: chatPopupReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }).concat([
      api.middleware,
      handleTenantNotFoundErrorMiddleware,
      usersApiSlice.middleware,
      typesenseApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
