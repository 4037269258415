import React, { FC, ReactElement, ReactNode, useMemo } from "react";
import { alpha, ThemeProvider } from "@mui/material/styles";
import { createTheme, CssBaseline, useMediaQuery } from "@mui/material";
import { Global, css } from "@emotion/react";
import { generateTheme } from "./theme";

import { useSelector } from "react-redux";
import { RootState } from "@/store";

import useDirection from "@/hooks/useDirection";
import { FeatureFlags } from "@/types/feature-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PaletteType } from "./palettes";

declare module "@mui/material/styles" {
  interface Palette {
    violet: Palette["primary"];
  }

  interface PaletteOptions {
    violet?: Palette["primary"];
  }
}

const Theme: FC<Props> = props => {
  const { children } = props;
  const currentDirection = useDirection();

  // Dynamic theme mode
  // Detects if the user's system is set to dark mode by default
  const isSystemDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const themeState = useSelector((state: RootState) => state.theme.theme);

  const { access_dark_mode }: FeatureFlags = useFlags();
  const theme = useMemo(() => {
    const mode = access_dark_mode
      ? themeState === "system"
        ? isSystemDarkMode
          ? "dark"
          : "light"
        : themeState
      : "light";

    const theme = createTheme(generateTheme(mode satisfies PaletteType));
    document.body.style.backgroundColor = theme.palette.customGrey.light;
    const root = document.documentElement; // Correctly reference the root element
    root.style.setProperty("--ila26-primary-main", theme.palette.primary.main);
    root.style.setProperty("--ila26-primary-light", theme.palette.primary.light);
    root.style.setProperty("--ila26-secondary-main", theme.palette.secondary.main);
    root.style.setProperty("--ila26-primary-contrastText", theme.palette.primary.contrastText);

    root.style.setProperty("--ila26-background-default", theme.palette.background.default);
    root.style.setProperty("--ila26-background-paper", theme.palette.background.paper);
    root.style.setProperty("--ila26-border-main", theme.palette.border.main);
    root.style.setProperty("--ila26-border-light", theme.palette.border.light);


    root.style.setProperty("--ila26-text-primary", theme.palette.text.primary);
    root.style.setProperty("--ila26-text-secondary", theme.palette.text.secondary);
    root.style.setProperty("--ila26-text-disabled", theme.palette.text.disabled);



    root.classList.toggle('ila26-dark', mode === 'dark');
    root.classList.toggle('ila26-light', mode === 'light');
    return theme;
  }, [access_dark_mode, isSystemDarkMode, themeState]);

  return (
    <ThemeProvider theme={{ ...theme, direction: currentDirection }}>
      <CssBaseline />
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
          }

          /* Scroll bar window */
          /* width */

          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */

          ::-webkit-scrollbar-track {
            background: ${theme.palette.mode === "light"
              ? theme.palette.customLightGrey.light
              : alpha(theme.palette.gray.contrastText, .4)};
          }

          /* Handle */

          ::-webkit-scrollbar-thumb {
            background: ${theme.palette.mode === "light"
              ? theme.palette.secondary.main
              : theme.palette.customLightGrey.light};
            border-radius: 4px;
          }

          /* Handle on hover */

          ::-webkit-scrollbar-thumb:hover {
            background: ${theme.palette.mode === "light"
              ? theme.palette.gray.contrastText
              : alpha(theme.palette.text.primary, 0.6)};
          }

          html {
            color-scheme: ${theme.palette.mode};
          }

        `}
      />
      {children}
    </ThemeProvider>
  );
};

interface Props {
  children: React.JSX.Element | ReactElement | ReactNode;
}

export default Theme;
