import React, { useEffect, ReactNode, useState } from "react";
import useDirection from "@/hooks/useDirection";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

interface LanguageCacheProviderProps {
  children: ReactNode;
}

const isBrowser = typeof document !== "undefined";
let insertionPoint: any;

if (isBrowser) {
  const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
  insertionPoint = emotionInsertionPoint ?? undefined;
}

const cacheRTL = createCache({
  key: "mui-style-rtl",
  stylisPlugins: [prefixer, rtlPlugin],
  insertionPoint,
  prepend: true,
});

const cacheLTR = createCache({
  key: "mui-style-ltr",
  insertionPoint,
  prepend: true,
});

const LanguageCacheProvider: React.FC<LanguageCacheProviderProps> = ({ children }) => {
  const currentDirection = useDirection();

  const [cache, setCache] = useState(currentDirection === "rtl" ? cacheRTL : cacheLTR);

  useEffect(() => {
    setCache(currentDirection === "rtl" ? cacheRTL : cacheLTR);

    if (isBrowser) {
      document.body.setAttribute("dir", currentDirection);
    }
  }, [currentDirection]);

  return (
    <CacheProvider value={cache}>
      <div dir={currentDirection}>{children}</div>
    </CacheProvider>
  );
};

export default LanguageCacheProvider;
