import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/ar"; 


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(CustomParseFormat);
dayjs.extend(relativeTime);

export default dayjs;

export const convertToLocalTime = (utcDateString: string) => {
  return dayjs.utc(utcDateString).tz(dayjs.tz.guess()).format("YYYY-MM-DDTHH:mm:ss");
};

export const convertToUTC = (utcDateString: string) => {
  return dayjs.tz(utcDateString, dayjs.tz.guess()).utc().format("YYYY-MM-DDTHH:mm:ss");
};
