import { LOCALES } from "./locales";
import messages_en from "./en.json";
import messages_fr from "./fr.json";
import messages_ar from "./ar.json";
import messages_es from "./es.json";
import { ILA26_IntlMessages } from "@ila-26/ui-kit-open-source";

export const messages = {
  [LOCALES.ENGLISH]: { ...messages_en, ...ILA26_IntlMessages?.en },
  [LOCALES.FRENCH]: { ...messages_fr, ...ILA26_IntlMessages?.fr },
  [LOCALES.ARABIC]: { ...messages_ar, ...ILA26_IntlMessages?.ar },
  [LOCALES.SPANISH]: { ...messages_es, ...ILA26_IntlMessages?.es },
};
