import { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    if (!import.meta.env.VITE_APP_GOOGLE_ANALYTICS_APP_ID) {
      console.warn("Google Analytics ID is missing");
      return;
    }

    window.gtag("config", import.meta.env.VITE_APP_GOOGLE_ANALYTICS_APP_ID);
  }, []);

  return null;
};

export default GoogleAnalytics;
