import { styled, SxProps, Typography, Box, IconButton } from "@mui/material";
import { Theme } from "@mui/system";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  marginBottom: "24px",
  lineHeight: "1.1",
  fontWeight: "800",
  [theme.breakpoints.up("lg")]: {
    fontSize: "42px",
  },
  "& span": {
    background: "linear-gradient(90deg, #951BB5 0%, #5A4CA8 35%, #4869B0 60.5%, #007998 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

export const dialogStyles: SxProps<Theme> = {
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: { xs: "92%", md: "750px" },
      borderRadius: "10px",
    },
  },
};

export const CloseIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 12,
  top: 12,
  padding: 0,
  zIndex: 10,
}));

export const MobileMockupBox = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90deg, #951BB5 0%, #5A4CA8 35%, #4869B0 60.5%, #007998 100%)",
  width: "100%",
  maxWidth: "260px",
  paddingTop: "10px",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    maxWidth: "100%",
    paddingTop: "10px",
  },
}));

export const SectionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flex: 1,
  padding: "15px",
  [theme.breakpoints.up("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "40px",
  },
}));

export const ImageWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const QrCodeBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "30px",
}));

export const DownloadAppBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: "16px",
  background: "linear-gradient(90deg, #951BB5 0%, #5A4CA8 35%, #4869B0 60.5%, #007998 100%)",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "20px",
  borderRadius: "8px",
  marginLeft: "auto",
  position: "fixed",
  width: "180px",
  bottom : "10px",
  right : "24px",
  zIndex : 10,
  ":dir(rtl) &": {
    right: "unset",
    left: "24px",
  },
}));

export const QrCodeCard = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  display: "flex",
  padding: "10px",
  borderRadius: "8px",
  aspectRatio: "1",
  maxWidth: "144px",
  width: "100%",
}));
