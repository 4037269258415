import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const classesTenantBox = {
  selected: "box-selected",
};

export const LogoIla26 = styled("img")({
  ["&"]: {
    width: "200px",
  },
});

export const TenantsBox = styled(Box)(({ theme }) => ({
  ["&"]: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    boxShadow: "none",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.customLightGrey.main
        : theme.palette.background.default,
    width: "70%",
    padding: "15px",
  },
}));

export const TenantBox = styled(Box)(({ theme }) => ({
  ["&"]: {
    borderRadius: 5,
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper,
    width: "90%",
    padding: "14px",
    margin: "15px",
    fontWeight: "bold",
    border: `1px solid transparent`,
    display: "flex",
    gap : "10px",
    [`&:hover, &.${classesTenantBox.selected}`]: {
      backgroundColor: theme.palette.lightPurple.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "& *": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    height: "18px",
    width: "20px",
    color: theme.palette.primary.main,
  },
}));
