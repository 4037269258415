import { Typography, Box, useTheme } from "@mui/material";
import mobileMockup from "@assets/images/mobile_mockup.svg";
import AppStoreBadge from "@assets/images/app-store-badge.svg";
import playStoreBadge from "@assets/images/play-store-badge.svg";
import messages from "@pages/layout/download-app/messages.ts";
import logo from "@assets/images/ilan26White.svg";
import { LogoIla26 } from "@/styles/style";

import {
  StyledTypography,
  MobileMockupBox,
  SectionBox,
  ImageWrapper,
} from "@pages/layout/download-app/styles";
import { useIntl } from "react-intl";

const BlockedDevices = () => {
  const { formatMessage: __ } = useIntl();

  const handleDownloadClick = () => {
    window.location.href = `${import.meta.env.VITE_APP_WEB_APP}/download`;
  };

  const theme = useTheme();
  return (
    <Box padding="1rem" height="100%">
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Box display="flex" width="100%" overflow="hidden" height="100%" flexDirection="column">
          <SectionBox sx={{ alignItems: "center" }}>
            <Box
              sx={{
                background: "linear-gradient(90deg, rgba(146,29,180,1) 0%, rgba(4,120,153,1) 100%)",
                padding: "0.5rem",
                borderRadius: 8,
                justifyContent: "center",
                alignItems: "centers",
                display: "flex",
                mb: 1,
                px: 2,
              }}
            >
              <Typography
                variant="h5"
                component="h1"
                fontWeight={400}
                color={theme.palette.primary.contrastText}
              >
                {__(messages.appTitle)}
              </Typography>
            </Box>

            <StyledTypography textAlign="center" sx={{ marginBottom: 1 }}>
              {__(messages.availableOnMobile, {
                available: (
                  <span>
                    {__(messages.available)} <br />
                  </span>
                ),
              })}
            </StyledTypography>
            <Typography
              variant="body1"
              mb={2}
              fontWeight={600}
              lineHeight={1}
              sx={{
                textAlign: "center",
                fontSize: { xs: "16px", md: "18px", lg: "22px" },
              }}
            >
              {__(messages.downloadDescription)}
            </Typography>

            <ImageWrapper sx={{ flexDirection: "row" }}>
              <img
                src={AppStoreBadge}
                alt="Apple Store"
                style={{ width: "120px" }}
                onClick={handleDownloadClick}
              />
              <img
                src={playStoreBadge}
                alt="Google Play"
                style={{ width: "120px" }}
                onClick={handleDownloadClick}
              />
            </ImageWrapper>
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center"></Box>
            </Box>
          </SectionBox>

          <Box
            sx={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MobileMockupBox sx={{ maxWidth: "100%", maxHeight: "55%" }}>
              <Box
                position="absolute"
                top="20px"
                sx={{ height: { xs: "20px", md: "25px", lg: "35px" } }}
              >
                <LogoIla26 src={logo} style={{ height: "100%" }} />
              </Box>
              <img
                src={mobileMockup}
                alt="Mobile Mockup"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "40%",
                }}
              />
            </MobileMockupBox>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockedDevices;
