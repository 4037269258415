import { AccessToken } from "@azure/core-auth";
import {
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";

// Configurations for MSAL
export const msalConfig = {
  auth: {
    // This is the ONLY mandatory field that you need to supply.
    clientId: import.meta.env.VITE_APP_CLIENT_ID,

    // Choose SUSI as your default authority.
    authority: import.meta.env.VITE_APP_AUTHORITY,

    // Mark your B2C tenant's domain as trusted.
    knownAuthorities: [import.meta.env.VITE_APP_KNOWN_AUTHORITIES],

    // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    redirectUri: import.meta.env.VITE_APP_REDIRECT_URI,

    // Indicates the page to navigate after logout.
    postLogoutRedirectUri: import.meta.env.VITE_APP_POST_LOGOUT_REDIRECT_URI,

    // If 'true', will navigate back to the original request location before processing the auth code response.
    navigateToLoginRequestUrl: import.meta.env.VITE_APP_NAVIGATE_TO_LOGIN_REQUEST_URL === "true",
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// B2C Policies
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_susi_v2",
    forgotPassword: "B2C_1_reset_v3",
    editProfile: "B2C_1_edit_profile_v2",
  },
  authorities: {
    signUpSignIn: {
      authority: import.meta.env.VITE_APP_AUTHORITY,
    },
    forgotPassword: {
      authority: import.meta.env.VITE_APP_AUTHORITY,
    },
    editProfile: {
      authority: import.meta.env.VITE_APP_AUTHORITY,
    },
  },

  // Authority domain.
  authorityDomain: import.meta.env.VITE_APP_AUTHORITY_DOMAIN,
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  ilanWebApi: {
    endpoint: import.meta.env.VITE_APP_API_ENDPOINT,
    scopes: {
      access_as_user: [import.meta.env.VITE_APP_ACCESS_AS_USER],
    },
  },
};

const extraQueryParameters = {
  "ui_locales": import.meta.env.VITE_APP_POLICY_LANG ?? "en",
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.ilanWebApi.scopes.access_as_user],
  extraQueryParameters: extraQueryParameters,
};

// Flag to avoid BrowserAuthErrors: Interaction_in_progress error
let isRunningFlag = false;

export async function fetchAccessToken(): Promise<AccessToken | null> {
  const scopes = loginRequest.scopes;
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length <= 0) {
    console.error("nope");
    return null;
  }
  const account = accounts[0];

  const silentRequest: SilentRequest = {
    account: account,
    scopes: scopes as string[],
    cacheLookupPolicy: CacheLookupPolicy.Default,
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(silentRequest);

    return { token: authResult.accessToken, expiresOnTimestamp: authResult.expiresOn!.getTime() };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError && !isRunningFlag) {
      isRunningFlag = true;
      console.error(`errorCode : ${error.errorCode}`);
      console.error(`errorMessage : ${error.errorMessage}`);
      console.error(`message : ${error.message}`);
      console.error(`name : ${error.name}`);
      console.error(`subError : ${error.subError}`);

      try {
        const redirectRequest: RedirectRequest = {
          account: account,
          scopes: scopes as string[],
          loginHint: account.username,
          extraQueryParameters: extraQueryParameters,
        };

        await msalInstance.handleRedirectPromise();
        await msalInstance.acquireTokenRedirect(redirectRequest);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          console.error(`err.errorCode : ${err.errorCode}`);
          console.error(`err.errorMessage : ${err.errorMessage}`);
          console.error(`err.message : ${err.message}`);
          console.error(`err.name : ${err.name}`);
          console.error(`err.subError : ${err.subError}`);
        }
      } finally {
        isRunningFlag = false;
      }
    }

    return null;
  }

  // console.log('TOKEN (' + (authParameters.scopes || []).join(' | ') + ' | ' + authResponse.expiresOn + ') ' + authResponse.accessToken);
}

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
